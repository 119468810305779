import React, { useState, useCallback, useEffect } from 'react';
import "./styles/globals.scss";
import styles from "./App.module.scss";
import sanityClient from "./client";
import imageUrlBuilder from "@sanity/image-url";
import classNames from "classnames/bind";
import ProgressiveImage from "react-progressive-graceful-image";
import moment from 'moment';

const builder = imageUrlBuilder(sanityClient);

const urlFor = (source) => {
    return builder.image(source);
};

function App() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [siteData, setSiteData] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [aboutActive, setAboutActive] = useState(false);
  const [time, setTime] = useState(moment().format("hh:mm:ss"))
  const c = classNames.bind(styles);

  const toggleActive = () => {
    if (aboutActive) {
      setAboutActive(false);
    } else {
      setAboutActive(true);
    }
  }

  const fetchSiteData = useCallback(async () => {
    const query = 
    `*[_type == "landingPage"][0] {
      email,
      instagram,
      projects[] {
        date,
        image,
        title
      },
      about
    }`;

    sanityClient
      .fetch(query)
      .then((data) => {
        setSiteData(data);
      })
      .catch(console.error);
  }, [setSiteData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    const interval = setInterval(() => {
      setTime(moment().format("hh:mm:ss"))
    }, 1000);

    if (!siteData) {
      fetchSiteData();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(interval);
    };
  }, [siteData, fetchSiteData]);

  if (!siteData) {return null}


  const cross = (<svg xmlns="http://www.w3.org/2000/svg" width="17.667" height="17.667" viewBox="0 0 17.667 17.667">
    <g id="Group_1" data-name="Group 1" transform="translate(-944.333 -213.5)">
      <line id="Line_3" data-name="Line 3" y2="17.667" transform="translate(953.167 213.5)" fill="none" stroke="#c1c1c1" strokeWidth="2"/>
      <line id="Line_4" data-name="Line 4" y2="17.667" transform="translate(962 222.333) rotate(90)" fill="none" stroke="#c1c1c1" strokeWidth="2"/>
    </g>
  </svg>)

  return (
    <div 
      className={styles.main}
      style={{
        width: windowDimensions.width,
        height: windowDimensions.height
      }}
    >
      <div className={styles.images}
        style={{
          width: windowDimensions.width,
          height: windowDimensions.height
        }}
      >
        {
          siteData.projects.map((project, index) => (

            <ProgressiveImage
            key={index}
            src={urlFor(project.image).width(Math.ceil(windowDimensions.width + 2)).height(Math.ceil(windowDimensions.height + 2)).url()}
            placeholder={urlFor(project.image).width(Math.ceil((windowDimensions.width + 2) * 0.2)).height(Math.ceil((windowDimensions.height + 2) * 0.2)).url()}
            >
              {(src, loading) => (
                  <img
                    src={src}
                    alt={project.title}
                    className={c({image: true}, {visible: index === currentImage})}
                    style={{ 
                      width: "auto",
                      height: windowDimensions.height + 1,
                      filter: loading ? "blur(10px)" : "blur(0px)" 
                    }}
                  />
                )}
            </ProgressiveImage>

          ))
        }
      </div>

      <div className={c({mobileAbout: true}, {visible: aboutActive && windowDimensions.width < 769})}
        style={{
          width: windowDimensions.width,
          height: windowDimensions.height
        }}
      >
        <div className={styles.filter}>
          <pre>{siteData.about}</pre>
        </div>
      </div>


      <div className={styles.overlay} 
        style={{
          width: windowDimensions.width,
          height: windowDimensions.height
        }}
      > 
        <div className={styles.heading}><h1>MELANIE ISSAKA</h1></div>
        <div className={c({corner: true}, {topleft: true})}>
          <p onClick={toggleActive}>{`ABOUT [${ aboutActive ? "-" : "+"}]`}</p>
          {aboutActive && windowDimensions.width >= 769 ? <pre>{siteData.about}</pre> : null}
        </div>
        <div className={c({corner: true}, {topright: true})}>
         
          <p>{time}</p>  
         
        </div>
        <div className={c({corner: true}, {botleft: true})}>
          <a href={siteData.instagram} target="_blank" rel="noreferrer" style={{display: "block"}}>INSTA</a>
          <a href={"mailto:" + siteData.email} target="_blank" rel="noreferrer" style={{display: "block"}}>EMAIL</a>
        </div>
        <div className={c({corner: true}, {botright: true})}>
          <p>FULL</p>
          <p>SITE</p>
          <p>SOON</p>
        </div>
        <div className={styles.title}><h2>{siteData.projects[currentImage].title}</h2></div>

        <div className={c({focus: true}, {hidden: aboutActive && windowDimensions.width < 769})}>
          <div className={styles.crossBox}></div>   
          <div className={styles.crossBox}></div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(0)}} >{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(1)}} >{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(2)}} >{cross}</div> 
          <div className={styles.crossBox}></div>   
          <div className={styles.crossBox}></div> 
          
          <div className={styles.crossBox}></div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(3)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(4)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(5)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(6)}}>{cross}</div> 
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(7)}}>{cross}</div>   
          <div className={styles.crossBox}></div> 
          
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(8)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(9)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(10)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(11)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(12)}}>{cross}</div> 
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(13)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(14)}}>{cross}</div> 
          
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(15)}} >{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(16)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(17)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(18)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(19)}}>{cross}</div> 
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(20)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(21)}}>{cross}</div> 
          
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(22)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(23)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(24)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(25)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(26)}}>{cross}</div> 
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(27)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(28)}}>{cross}</div> 
          
          <div className={styles.crossBox}></div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(29)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(30)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(31)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(32)}}>{cross}</div> 
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(33)}}>{cross}</div>   
          <div className={styles.crossBox}></div> 
          
          <div className={styles.crossBox}></div>   
          <div className={styles.crossBox}></div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(34)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(35)}}>{cross}</div>   
          <div className={styles.crossBox} onMouseEnter={() => {setCurrentImage(36)}}>{cross}</div> 
          <div className={styles.crossBox}></div>   
          <div className={styles.crossBox}></div> 
          
        </div>
      </div>
    </div>
  );
}

export default App;
